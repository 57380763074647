import { AxiosRequestConfig } from "axios";
import request from "./request";
import { Query } from "./types";

export async function getAllInvoices(
  parent_id: string | number,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "GET",
    url: `/client-area/get-invoices/${parent_id}`,
    params,
    ...config,
  });
}

export async function getInvoiceById(
  id: string | number,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "GET",
    url: `/client-area/get-invoice/${id}`,
    params,
    ...config,
  });
}
