<script setup lang="ts">
import { computed } from "vue";
import { FlatInstallation } from "@/types/InstallationTypes";
import { useContractStore } from "@/stores";
import { MeButton } from "@/libs/metha-components";

import InstallationInfo from "@/components/molecules/InstallationInfo.vue";
import InstallationCredential from "@/components/molecules/InstallationCredential.vue";
import SignContractActivator from "@/components/atoms/SignContractActivator.vue";
import InstallationContract from "@/components/atoms/InstallationContract.vue";

import { canSignContract } from "@/utils/contract";
const contractStore = useContractStore();

const props = defineProps<{
  installation: FlatInstallation;
}>();

function openContract() {
  contractStore.openContract(props.installation, "reverseCancellation");
}

const mostRecentContract = computed(() => {
  const contracts = props.installation.contracts ?? [null];
  return contracts[0];
});

const installationStatusAllowedToUpdateCredentials = computed(() => {
  const allowedStatus = ["active", "approved", "under_analysis", "suspended"];
  return allowedStatus.includes(props.installation.status);
});

const supplierAllowedToUpdateCredentials = computed(() => {
  const allowedSuppliers = ["cemig"];
  return allowedSuppliers.includes(props.installation.supplier);
});

function isPendingCredentials(installation: FlatInstallation) {
  if (typeof installation.pending_credentials === "boolean") {
    return installation.pending_credentials;
  }

  return true;
}

const needContractUpdate = computed(() => {
  return canSignContract(props.installation);
});

const textCancellation = computed(() => {
  return "<b>Motivo da cancelamento:</b> Você, voluntariamente, solicitou o cancelamento dos serviços oferecidos pela Metha Energia.";
});

const showReverseCancellation = computed(() => {
  const canceled = props.installation.status === "canceled";
  if (
    canceled &&
    (props.installation.status_reason === "tax_tariff" ||
      props.installation.status_reason === "inactive_installation" ||
      props.installation.status_reason === "incompatible_cpf")
  ) {
    return false;
  }
  const cancellation = props.installation.status === "under_cancellation";
  return cancellation || canceled;
});
</script>

<template>
  <div class="installation-card">
    <InstallationInfo
      :status="installation.status"
      :installationNumber="installation.installation_number"
      :address="installation.address"
      :supplier="installation.supplier"
    />
    <InstallationContract
      v-if="mostRecentContract"
      :contract="mostRecentContract"
    />
    <SignContractActivator
      v-if="needContractUpdate"
      :installation="installation"
    />
    <div
      v-if="showReverseCancellation"
      class="installation-card__box-sub-content"
    >
      <p
        class="installation-card__box-sub-content__text"
        v-html="textCancellation"
      ></p>
      <MeButton @click="openContract">Reverter cancelamento</MeButton>
    </div>
    <InstallationCredential
      v-else-if="
        isPendingCredentials(installation) &&
        supplierAllowedToUpdateCredentials &&
        installationStatusAllowedToUpdateCredentials
      "
      :installation="installation"
    />
  </div>
</template>

<style lang="scss" scoped>
.installation-card {
  background: #fff;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0px 2px 2px 0px #2125290f;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  &__box-sub-content {
    background-color: #e9ecef;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;

    &__text {
      color: #000;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.688rem;
      margin-bottom: 0.625rem;
    }
  }
}
</style>
