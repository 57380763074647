<template>
  <div class="demonstrative-alert">
    <p class="demonstrative-alert__text">
      Esta é uma fatura demonstrativa, portanto, está dispensada de pagamento. O
      valor desta fatura poderá ser compensado no próximo ciclo de faturamento.
    </p>
  </div>
</template>

<style lang="scss" scoped>
.demonstrative-alert {
  background-color: #e9e9e9;
  width: 100%;
  border-radius: 1rem;
  padding: 1rem;

  @media (min-width: 993px) {
    border-radius: 1.125rem;
    padding: 1.25rem;
  }

  &__text {
    font-weight: 500;
    text-align: left;
    font-size: 1rem;
    line-height: 1.375rem;

    @media (min-width: 993px) {
      font-size: 0.875rem;
      line-height: 1.5rem;
    }
  }
}
</style>
