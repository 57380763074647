import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "invoices-list-row" }
const _hoisted_2 = { class: "invoices-list-row__reference-month" }
const _hoisted_3 = { class: "invoices-list-row--smaller invoices-list-row--only-desktop" }
const _hoisted_4 = { class: "invoices-list-row--only-desktop" }
const _hoisted_5 = { class: "invoices-list-row__button invoices-list-row--only-mobile" }

import { computed } from "vue";
import { useRouter } from "vue-router";

import { MeButton, MeIcon } from "@/libs/metha-components";

import type { Invoice } from "@/types/InvoiceTypes";
import { formatDate, formatMoney, capitalize } from "@/utils/format";

import StatusTag from "@/components/atoms/StatusTag.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceListBodyRow',
  props: {
    invoice: {}
  },
  setup(__props: any) {

const props = __props;

const router = useRouter();

const dueDate = computed(() => {
  if (props.invoice.status === "demonstrative") return "---";

  return formatDate(props.invoice.due_date);
});

function redirectToDetailsPage(id: string) {
  router.push(`faturas/${id}`);
  return;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(capitalize)(_ctx.invoice.reference_month)), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(dueDate.value), 1),
    _createElementVNode("p", null, [
      _createVNode(StatusTag, {
        status: _ctx.invoice.status,
        invoice: ""
      }, null, 8, ["status"])
    ]),
    _createElementVNode("p", null, _toDisplayString(_unref(formatMoney)(_ctx.invoice.total_value)), 1),
    _createElementVNode("p", _hoisted_4, [
      _createVNode(_unref(MeButton), {
        onClick: _cache[0] || (_cache[0] = () => redirectToDetailsPage(_ctx.invoice.id)),
        variant: "outline",
        color: "foreground"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Ver detalhes ")
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_unref(MeIcon), {
        width: "20",
        height: "20"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("chevron-right")
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = () => redirectToDetailsPage(_ctx.invoice.id)),
      class: "invoices-list-row__mobile-link"
    })
  ]))
}
}

})