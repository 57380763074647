import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "view-invoice"
}
const _hoisted_2 = { class: "view-invoice__container" }
const _hoisted_3 = { class: "view-invoice__status-issue-date-wrapper" }
const _hoisted_4 = { class: "view-invoice__dates" }
const _hoisted_5 = { class: "view-invoice__dates__reference-month" }
const _hoisted_6 = { class: "view-invoice__dates__main-value" }
const _hoisted_7 = { class: "view-invoice__dates__due-date" }
const _hoisted_8 = { class: "view-invoice__dates__main-value" }
const _hoisted_9 = { class: "view-invoice__consumption-info" }
const _hoisted_10 = { class: "view-invoice__consumption-info__content" }
const _hoisted_11 = { class: "view-invoice__demonstrative-alert" }
const _hoisted_12 = { class: "view-invoice__buttons" }
const _hoisted_13 = {
  key: 0,
  class: "view-invoice__buttons__payments"
}
const _hoisted_14 = { class: "link view-invoice__buttons__download-invoice" }
const _hoisted_15 = { class: "view-invoice__past-discounts" }
const _hoisted_16 = { class: "view-invoice__past-discounts__graph" }
const _hoisted_17 = { class: "view-invoice__past-discounts__side" }

import { computed, ref, onBeforeMount, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useUserStore, useInvoicesStore } from "@/stores";

import { handleError } from "@/utils/handleError";
import { orderByReferenceMonth } from "@/utils/orderInvoices";
import { formatDate, capitalize } from "@/utils/format";

import InvoiceViewStatusTag from "@/components/atoms/InvoiceViewStatusTag.vue";
import DownloadFileButton from "@/components/atoms/DownloadFileButton.vue";

import ViewInvoiceSelectInvoice from "@/components/molecules/ViewInvoiceSelectInvoice.vue";
import UserAndInstallationInfo from "@/components/molecules/UserAndInstallationInfo.vue";
import ConsumptionGraph from "@/components/molecules/ConsumptionGraph.vue";
import InvoiceTotalAndDiscountValues from "@/components/molecules/InvoiceTotalAndDiscountValues.vue";
import PixPayment from "@/components/molecules/PixPayment.vue";
import ClientEconomy from "@/components/molecules/ClientEconomy.vue";
import InvoiceOverrun from "@/components/molecules/InvoiceOverrun.vue";
import InvoiceMeasurementsDate from "@/components/molecules/InvoiceMeasurementsDate.vue";
import DemonstrativeAlert from "@/components/molecules/DemonstrativeAlert.vue";

import PastConsumptionGraph from "@/components/organisms/PastConsumptionGraph.vue";
import PaymentMethodsModalWithActivator from "@/components/organisms/PaymentMethodsModalWithActivator.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewInvoicePage',
  setup(__props) {

const route = useRoute();
const router = useRouter();

const userStore = useUserStore();
const invoicesStore = useInvoicesStore();

const year = ref<number>(0);
const yearOptions = ref<number[]>([]);
const referenceMonthOptions = ref<{
  [key: number | string]: string[];
}>();

const hasInvoices = computed(() => {
  const invoices = invoicesStore.invoices ?? [];

  return invoices.length >= 1;
});

const invoice = computed(() => {
  return invoicesStore.selectedInvoice;
});

const isPaid = computed(() => {
  return invoice.value.status === "paid";
});

const isDemonstrative = computed(() => {
  return invoice.value.status === "demonstrative";
});

const dueDate = computed(() => {
  if (isDemonstrative.value) return "---";

  return formatDate(invoice.value.due_date);
});

const historyConsumptionChartInfo = computed(() => {
  return invoicesStore.getInvoiceHistoryConsumptionChartInfo();
});

async function loadInvoiceOnPage() {
  try {
    userStore.pageLoading = true;
    userStore.setPageTitle("Detalhes da Fatura");
    let id = route.params.id;
    id = Array.isArray(id) ? id[0] : id;

    await invoicesStore.getInvoiceById(id);
    await invoicesStore.getAllInvoices(userStore.selectedInstallation.id);

    if (!invoicesStore.selectedInvoice || !hasInvoices.value) {
      router.push("/faturas");
    }

    let invoices = orderByReferenceMonth(invoicesStore.invoices);
    const [, invoiceYear] =
      invoicesStore.selectedInvoice.reference_month.split("/");
    year.value = +invoiceYear;

    for (let i = 0; i < invoices.length; i += 1) {
      const item = invoices[i];
      const [, invoiceYear] = item.reference_month.split("/");
      yearOptions.value = [...new Set([...yearOptions.value, +invoiceYear])];

      if (!referenceMonthOptions.value) {
        referenceMonthOptions.value = {
          [invoiceYear]: [item.reference_month],
        };
      } else if (referenceMonthOptions.value[invoiceYear]) {
        referenceMonthOptions.value[invoiceYear] = [
          ...new Set([
            ...referenceMonthOptions.value[invoiceYear],
            item.reference_month,
          ]),
        ];
      } else {
        referenceMonthOptions.value = {
          ...referenceMonthOptions.value,
          [invoiceYear]: [item.reference_month],
        };
      }
    }
  } catch (error) {
    handleError(error, userStore.setLoadingError);
  } finally {
    userStore.pageLoading = false;
  }
}

watch(
  () => route.params.id,
  () => {
    loadInvoiceOnPage();
  }
);

onBeforeMount(async () => {
  await loadInvoiceOnPage();
});

return (_ctx: any,_cache: any) => {
  return (!_unref(userStore).pageLoading)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (invoice.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(ViewInvoiceSelectInvoice, {
                currentYear: year.value,
                primaryOptions: yearOptions.value,
                secondaryOptions: referenceMonthOptions.value,
                currentInvoice: invoice.value.reference_month
              }, null, 8, ["currentYear", "primaryOptions", "secondaryOptions", "currentInvoice"]),
              _createElementVNode("section", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", null, [
                    _createVNode(InvoiceViewStatusTag, {
                      status: invoice.value.status
                    }, null, 8, ["status"])
                  ]),
                  _createElementVNode("p", null, " Data emissão: " + _toDisplayString(invoice.value.issue_date ? _unref(formatDate)(invoice.value.issue_date) : "--/--"), 1)
                ]),
                _createVNode(UserAndInstallationInfo, { invoice: invoice.value }, null, 8, ["invoice"]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("p", _hoisted_5, [
                    _cache[0] || (_cache[0] = _createTextVNode(" Referente a ")),
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(capitalize)(invoice.value.reference_month)), 1)
                  ]),
                  _createElementVNode("p", _hoisted_7, [
                    _cache[1] || (_cache[1] = _createTextVNode(" Vencimento ")),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(dueDate.value), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", null, [
                      _createVNode(ConsumptionGraph, {
                        total: invoice.value.kwh_consumption,
                        active: invoice.value.kwh_injected,
                        discount: invoice.value.discount_percentage,
                        mainColor: "#FFCC12",
                        secondaryColor: "#E3E5E4"
                      }, null, 8, ["total", "active", "discount"])
                    ]),
                    _createVNode(InvoiceTotalAndDiscountValues, { invoice: invoice.value }, null, 8, ["invoice"]),
                    _createElementVNode("div", _hoisted_11, [
                      (isDemonstrative.value)
                        ? (_openBlock(), _createBlock(DemonstrativeAlert, { key: 0 }))
                        : _createCommentVNode("", true)
                    ]),
                    (!(isPaid.value || isDemonstrative.value))
                      ? (_openBlock(), _createBlock(PixPayment, {
                          key: 0,
                          auxiliarText: "Pague com Pix",
                          invoice: invoice.value
                        }, null, 8, ["invoice"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_12, [
                      (!(isPaid.value || isDemonstrative.value))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createVNode(PaymentMethodsModalWithActivator, { invoice: invoice.value }, null, 8, ["invoice"])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(DownloadFileButton, {
                          invoice: invoice.value,
                          disabled: !invoice.value.s3_url,
                          url: invoice.value.s3_url
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode(" Abrir 2ª via da fatura ")
                          ])),
                          _: 1
                        }, 8, ["invoice", "disabled", "url"])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(PastConsumptionGraph, {
                      labels: historyConsumptionChartInfo.value.labels,
                      primaryValues: historyConsumptionChartInfo.value.methaValues,
                      secondaryValues: historyConsumptionChartInfo.value.supplierValues
                    }, null, 8, ["labels", "primaryValues", "secondaryValues"])
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(ClientEconomy, {
                      totalEconomy: _unref(userStore).selectedInstallation.total_economy
                    }, null, 8, ["totalEconomy"]),
                    _createVNode(InvoiceOverrun, { invoice: invoice.value }, null, 8, ["invoice"])
                  ])
                ]),
                _createVNode(InvoiceMeasurementsDate, { invoice: invoice.value }, null, 8, ["invoice"])
              ])
            ], 64))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})